/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Human Resources Application
  Author: Hossam Ali
  Author URL: https://www.linkedin.com/in/hossam-ali-7bb41810b/
==========================================================================================*/

export default [
  {
    url: "/",
    name: "Dashboard",
    slug: "home",
    icon: "HomeIcon",
    permission: 'roles_list'
  },

  {
    url: null,
    name: "Reports",
    icon: "FileIcon",

    submenu: [
      {
        url: null,
        name: "HR Reports",
        icon: "FileTextIcon",
        submenu: [
          {
            url: "/hr-report/main-report",
            name: "Main Report",
            slug: "main-report",
            permission: 'main_report',
          },
          {
            url: "/hr-report/absence-report",
            name: "Absence Report",
            slug: "absence-report",
            permission: 'roles_list',
          },
          {
            url: "/hr-report/vacation-report",
            name: "Vacation Report",
            slug: "vacation-report",
            permission: 'roles_list',
          },
          {
            url: "/hr-report/mission-report",
            name: "Mission Report",
            slug: "mission-report",
            permission: 'mission_report',
          },
          {
            url: "/hr-report/excuse-report",
            name: "Excuse Report",
            slug: "excuse-report",
            permission: 'execuse_report',
          },
          // {
          //   url: "/hr-report/working-lessthan-6hrs-report",
          //   name: "Working Less Than 6 Hours Report",
          //   slug: "working-lessthan-6hrs-report",
          //   permission:'execuse_report',
          // },
          {
            url: "/hr-report/sammry-report",
            name: "Summary Report",
            slug: "summary-report",
            permission: 'execuse_report',
          },
          // {
          //   url: "/hr-report/variables-report",
          //   name: "Variables Report",
          //   slug: "variables-report",
          // },
          {
            url: "/hr-report/all-report",
            name: "All in one report",
            slug: "all-report",
            permission: 'main_report',
          },
          {
            url: "/hr-report/hr-request-report",
            name: "HR request report",
            slug: "hr-request-report",
            permission: 'main_report',
          },
          {
            url: "/hr-report/penalty-report",
            name: "penalty report",
            slug: "penalty-report",
            permission: 'main_report',
          },
          {
            url: "/hr-report/daily-report",
            name: "daily report",
            slug: "daily-report",
            permission: 'main_report',
          },
        ],
      },
      {
        url: null,
        name: "ERP Reports",
        icon: "FileIcon",
        submenu: [
          {
            url: "/erp-reports/erp-reports",
            name: "ERP Reports",
            slug: "main_report",
            permission: 'main_report',
          },
          // {
          //   url: "",
          //   name: "HRWorkerPlanner(Importing Shifts)",
          //   slug: "HRWorkerPlanner",
          // },
          // {
          //   url: "",
          //   name: "Uploading HRTimeAttendance_IN&OUT",
          //   slug: "HRTimeAttendance_IN&OUT",
          // }
        ],
      }

    ],
  },
  {
    url: null,
    name: "Administrator",
    icon: "SettingsIcon",
    slug: "show_Administrator",
    permission: '',
    submenu: [
      {
        url: "/GetAll/Roles",
        name: "Roles",
        slug: "Roles",
        permission: 'roles_list',

      },
      // {
      //   url: "",
      //   name: "Log entries",
      //   slug: "Log_entries",
      //   permission:'roles_list',

      // },
      {
        url: "/GetAll/SignInOut_logs",
        name: "Sign in/out log",
        slug: "in_outLOG",
        permission: 'signin_out_log',

      },
      {
        url: "/GetAll/Reporting_to",
        name: "Reporting To",
        slug: "ReportingTo",
        permission: 'reporting_to',

      }
    ]
  },
  {
    url: "/GetAll/TopManagement",
    name: "Top Managment",
    icon: "UsersIcon",
    slug: "Top",
    permission: 'reporting_to',
  },
  // {
  //   url: null,
  //   name: "Audit",
  //   icon: "InfoIcon",
  //   slug: "audit"
  // },
  // {
  //   url: null,
  //   name: "Time",
  //   icon: "ClockIcon",
  //   slug: "time"
  // },
  // {
  //   url: null,
  //   name: "Discount",
  //   icon: "PercentIcon",
  //   slug: "discount"
  // },
  {
    url: "/GetAll/Time",
    name: "Time",
    icon: "ClockIcon",
    slug: "Time",
    permission: 'times_list',

  },
  {
    url: "/GetAll/News",
    name: "News",
    icon: "FileTextIcon",
    slug: "News",
    permission: 'news_list',
  },
  {
    url: null,
    name: "Users",
    icon: "UserIcon",
    permission: '',
    submenu: [
      {
        url: "/GetAll/Company",
        name: "Company",
        icon: "TrendingUpIcon",
        slug: "GetAll Companies",
        permission: 'companies_list',

      },
      {
        url: "/GetAll/Department",
        name: "Department",
        icon: "LayersIcon",
        slug: "GetAll Departments",
        permission: 'departments_list',

      },
      {
        url: "/GetAll/Job",
        name: "Job",
        icon: "BriefcaseIcon",
        slug: "GetAll jobs",
        permission: 'job_titles_list',

      },
      {
        url: "/GetAll/Category",
        name: "Category",
        icon: "TrendingUpIcon",
        slug: "GetAll Category",
        permission: 'categories_list',
      },
      {
        url: "/GetAll/Employee",
        name: "Employee",
        icon: "UserIcon",
        slug: "GetAll Employees",
        permission: 'users_list',
      },
      {
        url: "/GetAll/Employee/manager-replacement",
        name: "Manager replacement",
        icon: "UserIcon",
        slug: "Manager replacement",
        permission: 'users_list',
      }
    ]
  },
  {
    url: null,
    name: "Location",
    icon: "GlobeIcon",
    permission: '',
    submenu: [
      {
        url: "/Location/GetAll/City",
        name: "City",
        icon: "MapPinIcon",
        slug: "GetAll Cities",
        permission: 'cities_list',
      },
      {
        url: "/Location/GetAll/Areas",
        name: "Areas",
        icon: "MapIcon",
        slug: "GetAll Areas",
        permission: 'areas_list',
      }
    ]
  },
  {
    url: "/GetAll/Policies",
    name: "Company Policies",
    icon: "PocketIcon",
    slug: "GetAll Policies",
    permission: 'policies_list',
  },
  {
    url: "/GetAll/FAQ",
    name: "FAQ",
    icon: "HelpCircleIcon",
    slug: "GetAll FAQ",
    permission: 'faqs_list',
  },
  // {
  //   url: "/GetAll/Notification",
  //   name: "Notifications",
  //   icon: "BellIcon",
  //   slug: "notify",
  // },

];
